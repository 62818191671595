<template>
  <div class="user-card-admin" v-if="user">
    <TitleBar :title="$locale[fileType]" icon="mf_images" />
    <div class="user-file-verify">
      <div @click="viewFile" :class="`file skeleton`">
        <figure :class="`figure ${fileType}`" :style="`--img:url('${filePayload}')`"></figure>
      </div>
      <AdminVerificationsStatusSelect @select="(opt) => (verifyStatus = opt)" skeleton class="status" :selected="verifyStatus" />
    </div>
    <AdminVerificationsComments
      ref="comments"
      :comments="comments"
      :total="totalComments"
      @cancel="answerCancel"
      @accept="answerSend"
      @input="inputMessage"
      @more="getComments"
      :loading="loading"
      :loadingMore="loadingMore"
    />
  </div>
</template>

<script>
export default {
  props: ["user", "fileType"],
  components: {
    UserIdentityDocs: () => import("../user/UserIdentityDocs.vue"),
    AdminVerificationsStatusSelect: () => import("./AdminVerificationsStatusSelect.vue"),
    AdminVerificationsComments: () => import("./AdminVerificationsComments.vue"),
  },
  data: function() {
    return {
      loading: false,
      loadingMore: false,
      target: null,
      message: "",
      verifyStatus: null,
      pageComments: 0,
      typeComments: `${this.fileType}-verification`,
      comments: [],
      totalComments: 0,
    };
  },
  computed: {
    isAccepted: function() {
      return this.verifyStatus === "accept";
    },
    filePayload: function() {
      let src = "";

      if (this.user.payload && this.user.payload[this.fileType]) {
        src = this.user.payload[this.fileType].Location;
      }

      return src;
    },
  },
  methods: {
    viewFile: function() {
      if (!this.user.payload || !this.user.payload[this.fileType]) {
        return;
      }

      this.file = this.user.payload[this.fileType].Location;
      const ext = this.$global.getExtensionFromFilename(this.file);
      const media = { type: ext, src: this.file };
      this.$root.$emit("MediaVisor", { media: [media] });
    },
    answerCancel: function() {
      this.message = "";
      this.target.innerHTML = "";
    },
    inputMessage: function({ target }) {
      let [message] = [target.innerHTML];
      this.target = target;
      this.message = message;
    },
    answerSend: async function() {
      this.isLoading(true);
      try {
        const data = {
          userId: this.user._id,
          comment: this.message,
          verifyStatus: this.verifyStatus,
          type: this.typeComments,
        };
        const response = await this.$api.post(`${process.env.VUE_APP_API_HOST}admin/verifications-answer`, data);
        this.answerSuccess(response.data);
      } catch (error) {
        this.ialert.go({ title: "Error", message: error, icon: "error" });
      }
      this.isLoading(false);
    },
    answerSuccess: function({ message, success, answer }) {
      if (success) {
        answer.user = this.$user;
        this.comments.unshift(answer);
        this.user.hasResponse = true;
        this.$refs.comments.commentCancel();
        this.$emit("update", this.user);
        return this.answerCancel();
      }
      return this.ialert.go({ title: "Error", message: message, icon: "error" });
    },
    words: function(key) {
      return this.$store.state.locale.words[key];
    },
    getComments: async function(options = {}) {
      this.loadingMore = true;
      let limit = options.limit || 10;

      try {
        this.pageComments++;
        let query = `&page=${this.pageComments}&userId=${this.user._id}&start=1&limit=${limit}&type=${this.typeComments}`;
        const result = await this.$api.get(`${process.env.VUE_APP_API_HOST}admin/user-comments?${query}`);

        if (this.pageComments <= 1) {
          this.comments = result.data.items;
          this.totalComments = result.data.total;
        } else {
          this.comments.push(...result.data.items);
        }
      } catch (error) {
        this.pageComments--;
        console.log(error);
      }

      if (options.limit) {
        this.pageComments = 0;
      }

      this.loadingMore = false;
    },
    getStatus: function() {
      if (this.fileType && this.user.payload) {
        let status = this.user.payload && this.user.payload[this.fileType] && this.user.payload[this.fileType].adminStatus;
        this.verifyStatus = status;
      }
    },
  },
  mounted: function() {
    this.getStatus();
    this.getComments({ limit: 1 });
  },
};
</script>

<style lang="scss">
.user-card-admin {
  &:hover {
    .title-bar {
      color: #bd0909;
      background-color: #bd09090f;
    }
  }
  .user-file-verify {
    display: block;
    .file {
      height: calc(256px + #{$mpadding * 2});
      overflow: hidden;
      border-bottom: solid 1px $alto;
      display: flex;
      justify-content: center;
      flex-direction: column;
    }
    .figure {
      display: flex;
      justify-content: center;
      flex-direction: column;
      width: 100%;
      height: 100%;
      margin: auto;
      background-image: var(--img);
      background-size: cover;
      align-self: center;
      &.picture {
        width: 256px;
        margin: $mpadding;
        border-radius: 50%;
      }
    }
    img {
      max-width: 100%;
      display: block;
    }
  }
}
</style>
